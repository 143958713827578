import apiConfig from '../api-config'
import { AuthProvider } from '../authorization'

export class BaseController {
  readonly apiHost: string
  readonly apiPathDigitalId: string
  readonly apiPathPfmla: string

  fetchController: Record<string, any>

  constructor () {
    const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
    const configKey = (isGlobalEnabled ? 'global' : 'regional')

    this.apiHost = apiConfig[configKey].apiHost ?? ''
    this.apiPathDigitalId = apiConfig[configKey].paths.digitalId ?? ''
    this.apiPathPfmla = apiConfig[configKey].paths.pfmlaExport ?? ''
    this.fetchController = {}
  }

  async getHeaders (method: string, file: boolean = false, impersonateKerb: string = ''): Promise<Headers> {
    const token = await new AuthProvider().getToken()

    const headers: Headers = new Headers()

    if (!file) {
      headers.append('Content-Type', 'application/json')
      headers.append('Authorization', 'Bearer ' + token)
    }

    if (impersonateKerb !== '') {
      headers.append('X-Impersonated-User', impersonateKerb)
    }

    return headers
  }

  async useFetch (
    method: string,
    fetchKey: string,
    url: string,
    body: any = null,
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<any> {
    if (this.fetchController[fetchKey] !== null && this.fetchController[fetchKey] !== undefined) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: any = {
      method,
      headers: await this.getHeaders(method, file, impersonateKerb),
      signal
    }
    if (body !== null) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    // clear out controller
    this.fetchController[fetchKey] = null

    return response
  }

  // Calls an endpoint that checks if the current user exists in LDAP
  async getLDAPAuth (): Promise<any> {
    return await this.useFetch('GET', 'initialize', `${this.apiHost}/${this.apiPathPfmla}/initialize`)
  }
}
