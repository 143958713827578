const appConfig = {
  "name": "pfmla-v2",
  "version": "0.1.0",
  "stage": "prod",
  "deployThisBuild": false,
  "okta": {
    "issuer": "https://okta.mit.edu/oauth2/aus6sh93rjqnQuszg697",
    "clientId": "35d81eon65aff1dgdfdi34gp6v",
    "redirectUris": [
      "https://hrfeeds-mr.atlas-apps.mit.edu"
    ],
    "postLogoutRedirectUris": [
      "https://hrfeeds-mr.atlas-apps.mit.edu/logout"
    ],
    "scopes": [
      "address",
      "digital-id/user",
      "email",
      "offline_access",
      "openid",
      "pfmla/export",
      "phone",
      "profile",
      "user/admin.pfml",
      "user/all"
    ]
  },
  "amplify": {
    "userPoolId": "us-east-1_bCu6BU8d2",
    "userPoolWebClientId": "35d81eon65aff1dgdfdi34gp6v",
    "oauth": {
      "domain": "atlas-auth.mit.edu",
      "scope": [
        "digital-id/user",
        "openid",
        "pfmla/export",
        "profile"
      ],
      "redirectUris": [
        "https://hrfeeds-mr.atlas-apps.mit.edu"
      ],
      "postLogoutRedirectUris": [
        "https://hrfeeds-mr.atlas-apps.mit.edu/logout"
      ],
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  }
};
export default appConfig;