import appConfig from '../../app-config-global'
import { AuthorizationController } from '../controller'
import { AuthProvider as AwsAuthProvider } from '@mit/aws-react'

export class AuthProvider {
  authorizationController = new AuthorizationController()

  getToken = async (): Promise<string> => {
    // console.log('getToken appConfig', appConfig)
    const authorizationToken = await new AwsAuthProvider(
      Object.assign(appConfig)
    ).getToken()

    return authorizationToken
  }

  logout = async (): Promise<any> => {
    await new AwsAuthProvider(Object.assign(appConfig)).signOut()
  }

  getAuthorization = async (): Promise<any> => {
    return await this.authorizationController.isAuthorized()
  }
}
