import { Action, action, thunk, Thunk } from 'easy-peasy'

export interface State {
  counter: number
}
export interface Actions {
  updateCounter: Action<this, number>
}

// eslint-disable-next-line
export interface Thunks {
  updateCounterThunk: Thunk<this, number>
}

export interface CounterModel extends State, Actions, Thunks {}

export const counterModel: CounterModel = {
  counter: 0,
  /* eslint-disable */
  // ACTIONS
  updateCounter: action((state, payload) => {
    state.counter = state.counter + 1 * payload
  }),

  // THUNKS
  updateCounterThunk: thunk(async (actions, payload) => {
    setTimeout(() => actions.updateCounter(payload), 1000)
  })
  /* eslint-enable */
}
