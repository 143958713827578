const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "digitalId": "digital-id-v1",
      "pfmlaExport": "pfmla-export-v1"
    }
  },
  "global": {
    "apiHost": "https://hrfeeds.mit.edu/api",
    "paths": {
      "digitalId": "digital-id/v1",
      "pfmlaExport": "pfml-export/v1"
    }
  }
};
export default apiConfigs;