import { Action, action } from 'easy-peasy'

export interface State {
  isAuthenticated: boolean
  isLoading: boolean
}
export interface Actions {
  setIsAuthenticated: Action<this, boolean>
  setIsLoading: Action<this, boolean>
}

// eslint-disable-next-line
export interface Thunks {}

export interface GlobalModel extends State, Actions, Thunks {}

export const globalModel: GlobalModel = {
  isAuthenticated: false,
  isLoading: true,

  // Actions
  // eslint-disable-next-line
  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload
  }),
  // eslint-disable-next-line
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  })
}
