import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './scss/main.scss'
import { StoreProvider } from 'easy-peasy'
import { store, useStoreState } from './store'
import { Initialization } from '@mit/aws-react'
import { useAppInitializer } from './services/initializer'
import appConfig from './app-config-global'
import { Logout } from './components/Logout';

const RootComponent: React.FC = () => {
  const { initializeApp } = useAppInitializer()

  const AppLazyLoad = React.lazy(
    async () =>
      await import('./App').then((module) => ({
        default: module.default
      }))
  )

  return (
    <Router>
      <Routes>
        <Route path='/logout' element={< Logout />} />
        <Route path='*' element={
          <Initialization
            App={AppLazyLoad}
            appInitializer={initializeApp}
            appConfigData={appConfig}
            loaderProps={{
              backgroundColor: '#175c5f',
              contactEmail: 'help@mit.edu',
              name: 'WorkPartners Feed Administration',
              type: 'Default'
            }}
          />
        }>
        </Route>
      </Routes>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <RootComponent />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
