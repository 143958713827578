import { appInitialized } from '@mit/aws-react'
import { useEffect, useState } from 'react'
import { AuthProvider } from '../api/authorization'
import { useStoreActions } from '../store/hooks'

interface UseAppInitializationReturn {
  initializeApp: () => Promise<void>
  initializeApi: boolean
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const authProvider = new AuthProvider()
  const { setIsLoading, setIsAuthenticated } = useStoreActions((actions: { globalModel: any }) => actions.globalModel)
  const [initializeApi, setInitializeApi] = useState(false)

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false
    })
  }, [])

  const initializeApp = async (): Promise<void> => {
    const isLoading = false
    const hasError = false
    let hasAccess = false

    await authProvider
      .getAuthorization()
      .then((result: { status: number }) => {
        hasAccess = result.status === 200
      })
      .catch((error: any) => {
        console.error(error)
      })

    setIsAuthenticated(hasAccess)
    setIsLoading(isLoading)
    setInitializeApi(hasAccess)

    appInitialized({
      isLoading,
      hasAccess,
      hasError
    })
  }

  return { initializeApi, initializeApp }
}
